<template>
	<div>
		<Header :title="perState===0 ? '新增演出' : perState===1 ? '演出详情' : '编辑演出'" @back="onBack(false)"></Header>
		<a-spin :spinning="loading">
			<a-form ref="form" :model="modelRef" scrollToFirstError name="form" :labelCol="{span: 4, xxl: 3}"
				:wrapperCol="{span: 18, xxl: 19 }" @finish="onSubmit">
				<div style="margin-top: 30px;">
					<a-form-item label="演出名称" name="name" :rules="[{required: true, message: '必填项不允许为空'}]">
						<a-input v-model:value="modelRef.name" :disabled="perState===1" placeholder="请输入演出名称"></a-input>
					</a-form-item>

					<a-form-item label="演出编码" name="code" :rules="[{required: true, message: '必填项不允许为空'}]">
						<a-input v-model:value="modelRef.code" :disabled="perState===1" placeholder="请输入演出编码"></a-input>
					</a-form-item>

					<a-form-item label="演出评分" name="score" :rules="[{required: true, message: '必填项不允许为空'}]">
						<a-input-number v-model:value="modelRef.score" :min="0" :disabled="perState===1"
							placeholder="请输入"></a-input-number>
					</a-form-item>

					<a-form-item label="演出简介" name="description" :rules="[{required: true, message: '必填项不允许为空'}]">
						<a-textarea v-model:value="modelRef.description" :disabled="perState===1" placeholder="请输入演出简介"></a-textarea>
					</a-form-item>

					<a-form-item label="演出海报" name="posterUrl" :rules="[{required: true, message: '必填项不允许为空'}]" extra="建议图片比例：624 : 728">
						<a-upload v-model:file-list="fileList" list-type="picture-card"
							action="/admin/ajaxUpload.do" accept="image/*"
							@change="onUploadChange"
						>
							<div v-if="fileList.length < 1">
								<Icon icon="PlusOutlined"></Icon>
								<div style="margin-top: 8px">Upload</div>
							</div>
						</a-upload>
					</a-form-item>

					<a-form-item label="演出分类" name="classifyId">
						<preformClassify v-model:value="modelRef.classifyId"></preformClassify>
						<!-- <a-select v-model:value="modelRef.classifyId" :disabled="perState===1" placeholder="请选择演出分类">
							<a-select-option v-for="item in classifyList" :key="item.id" :value="item.id">{{ item.name }}</a-select-option>
						</a-select> -->
					</a-form-item>

					<a-form-item label="演出标签" name="tagId" :rules="[{ required: true, message: '必选项不允许为空' }]">
						<a-select v-model:value="modelRef.tagId" :disabled="perState===1" placeholder="请选择演出标签">
							<a-select-option v-for="item in tagList" :key="item.id" :value="item.id">{{ item.name }}</a-select-option>
						</a-select>
					</a-form-item>

					<!-- <a-form-item label="演出城市" name="cityIds" :rules="[{required: true, message: '必填项不允许为空'}]">
						<addressSelect v-model:value="modelRef.cityIds" :level="3" :disabled="perState===1"></addressSelect>
					</a-form-item>

					<a-form-item label="详细地址" name="coordinate" :rules="[{required: true, message: '必填项不允许为空'}]">
						<a-form-item-rest>
							<a-input v-model:value="modelRef.address" :disabled="perState===1" style="width:350px;" placeholder="请输入详细地址"></a-input>
						</a-form-item-rest>
						<a-input v-model:value="modelRef.coordinate" :disabled="perState===1" style="margin-left: 20px;width:200px;" placeholder="请填写地址坐标"></a-input>
						<a-button :disabled="perState===1" type="primary" @click="onOpenWin">坐标拾取器</a-button>
					</a-form-item> -->
					<a-form-item label="演出场地" name="siteId" :rules="[{required: true, message: '必选项不允许为空'}]">
						<a-form-item-rest>
							<site v-model:value="modelRef.siteId" type="radio"></site>
						</a-form-item-rest>
					</a-form-item>
					
					<a-form-item label="座位图" name="seatImg" :rules="[{required: true, message: '必选项不允许为空'}]">
						<a-upload v-model:file-list="seatFileList" list-type="picture-card" action="/admin/ajaxUpload.do"
							accept="image/*" @change="handleChange">
							<div v-if="seatFileList.length < 1">
								<Icon icon="PlusOutlined"></Icon>
								<div style="margin-top: 8px">Upload</div>
							</div>
						</a-upload>
					</a-form-item>

					<a-form-item label="演出详情" name="detail">
						<div style="position: relative;z-index: 2;">
							<Editor v-model:value="modelRef.detail"></Editor>
						</div>
					</a-form-item>

					<a-form-item label="演出服务" name="serviceIds" :rules="[{required: true, message: '必选项不允许为空'}]" extra="注: 【实体票】与【电子票】必选其一; 【有条件退】与【不可退】必选其一">
						<a-checkbox-group v-model:value="modelRef.serviceIds">
							<a-checkbox v-for="item in tipsList" :key="item.id" :value="item.id" :disabled="serviceDisabled(item.id)">{{ item.title }}</a-checkbox>
						</a-checkbox-group>
					</a-form-item>
					
					<a-form-item v-if="modelRef.serviceIds.includes(2)" label="售后规则" name="ruleId" :rules="[{required: true, message: '必选项不允许为空'}]">
						<a-select v-model:value="modelRef.ruleId" :disabled="perState===1" placeholder="请选择售后规则">
							<a-select-option v-for="item in rulesList" :key="item.id" :value="item.id">{{ item.name }}</a-select-option>
						</a-select>
					</a-form-item>

					<a-form-item label="演出须知" name="noticeIds" :rules="[{required: true, message: '必选项不允许为空'}]">
						<a-select v-model:value="modelRef.noticeIds" mode="multiple" :disabled="perState===1" placeholder="请选择演出服务">
							<a-select-option v-for="item in noticeList" :key="item.id" :value="item.id">{{ item.title }}</a-select-option>
						</a-select>
					</a-form-item>
					
					

					<!-- <a-form-item label="是否自营" name="isSelfSupport" :rules="[{required: true, message: '必填项不允许为空'}]">
						<a-radio-group :disabled="perState===1" v-model:value="modelRef.isSelfSupport" name="radioGroup">
							<a-radio :value="1">是</a-radio>
							<a-radio :value="0">否</a-radio>
						</a-radio-group>
					</a-form-item>

					<div v-if="modelRef.isSelfSupport">
						<a-form-item label="所属影院" name="cinemaId" :rules="[{required: true, message: '必填项不允许为空'}]">
							<a-select v-model:value="modelRef.cinemaId" :disabled="perState===1" placeholder="请选择所属影院" @change="clearHall">
								<a-select-option v-for="item in cinemaList" :key="item.id" :value="item.id">{{ item.name }}</a-select-option>
							</a-select>
						</a-form-item>
						<a-form-item label="演出影厅" name="hallId" :rules="[{required: true, message: '必填项不允许为空'}]">
							<a-button v-if="!modelRef.cinemaId" disabled>请选择所属影院</a-button>
							<a-form-item-rest v-else>
								<cinema v-model:value="modelRef.hallId" :id="modelRef.cinemaId" type="radio"></cinema>
							</a-form-item-rest>
						</a-form-item>
					</div> -->

					<a-form-item label="订单限购" name="orderLimit" :rules="[{ required: true, message: '必填项不允许为空' }]" extra="购买该演出票时，每笔订单最多可购买张数；填0不限购；">
						<a-input-number v-model:value="modelRef.orderLimit" placeholder="请输入" :min="0" :precision="0"></a-input-number>
					</a-form-item>

					<a-form-item label="是否参与抢票动向" name="isPeopleRecommend">
						<a-radio-group :disabled="perState===1" v-model:value="modelRef.isPeopleRecommend" name="radioGroup">
							<a-radio :value="1">是</a-radio>
							<a-radio :value="0">否</a-radio>
						</a-radio-group>
					</a-form-item>

					<a-form-item label="是否参与今日必抢" name="isDayRecommend">
						<a-radio-group :disabled="perState===1" v-model:value="modelRef.isDayRecommend" name="radioGroup">
							<a-radio :value="1">是</a-radio>
							<a-radio :value="0">否</a-radio>
						</a-radio-group>
					</a-form-item>

					<a-form-item label="是否参与本周必看" name="isWeekRecommend">
						<a-radio-group :disabled="perState===1" v-model:value="modelRef.isWeekRecommend" name="radioGroup">
							<a-radio :value="1">是</a-radio>
							<a-radio :value="0">否</a-radio>
						</a-radio-group>
					</a-form-item>
					
					<a-form-item label="是否演出攻略推荐" name="isRecommend">
						<a-radio-group v-model:value="modelRef.isRecommend" @change="onChange">
							<a-radio :value="1">是</a-radio>
							<a-radio :value="0">否</a-radio>
						</a-radio-group>
					</a-form-item>

					<div v-if="modelRef.isRecommend">
						<a-form-item label="演出推荐标题" name="recommendTitle" :rules="[{ required: true, message: '必填项不允许为空' }]">
							<a-input v-model:value="modelRef.recommendTitle" placeholder="请输入演出推荐标题"></a-input>
						</a-form-item>
						
						<a-form-item label="秒懂剧情" name="overview" :rules="[{ required: true, message: '必填项不允许为空' }]">
							<div style="position: relative;z-index: 2;">
								<Editor v-model:value="modelRef.overview"></Editor>
							</div>
						</a-form-item>
						
						<a-form-item label="必看理由" name="reason" :rules="[{ required: true, message: '必填项不允许为空' }]">
							<div style="position: relative;z-index: 2;">
								<Editor v-model:value="modelRef.reason"></Editor>
							</div>
						</a-form-item>
					</div>

					<a-form-item label="票档" name="tickets" :rules="[{required: true, message: '必填项不允许为空'}]">
						<a-row>
							<a-col>
								<a-button :disabled="perState===1" type="primary" @click="onAddTicketLine">新增票档</a-button>
								<div style="margin-top: 20px;position: relative;z-index: 1;">
									<a-table rowKey="rowIndex" :pagination="false" :columns="columns" :dataSource="modelRef.tickets" :scroll="{ x: 1200 }">
										<template #bodyCell="{ column, record, index }">
											<template v-if="column.key === 'memberFirstBuy'">
												<div>
													<div>{{ record.memberFirstBuy ? '允许提前购票' : '不允许提前购票' }}</div>
													<div v-if="record.memberFirstBuy">
														提前购票时间：{{ transDateTime(record.memberLimitTime) }}
													</div>
												</div>
											</template>
											<template v-if="column.key === 'action'">
												<a-dropdown :trigger="['click', 'hover']">
													<a-button @click.prevent>操作 <Icon icon="DownOutlined"></Icon>
													</a-button>
													<template #overlay>
														<a-menu>
															<div :disabled="perState===1" type="primary"
																@click="onEditOne(record, index)">
																<a-menu-item>
																	编辑
																</a-menu-item>
															</div>
															<div :disabled="perState===1" type="primary"
																@click="onDeleteOne(record, index)">
																<a-menu-item>
																	删除
																</a-menu-item>
															</div>
														</a-menu>
													</template>
												</a-dropdown>
											</template>
										</template>
									</a-table>
								</div>
							</a-col>
						</a-row>
					</a-form-item>
					
					<a-form-item label="状态" name="isDisabled">
						<a-radio-group v-model:value="modelRef.isDisabled">
							<a-radio :value="0">启用</a-radio>
							<a-radio :value="1">启用</a-radio>
						</a-radio-group>
					</a-form-item>
				</div>
				<a-row>
					<a-col v-if="perState!==1" :span="24" style="margin-top: 20px;text-align: center;">
						<a-button type="primary" html-type="submit">提交</a-button>
						<a-button style="margin-left: 20px;" @click="onBack(false)">取消</a-button>
					</a-col>
					<a-col v-else :span="24" style="margin-top: 20px;text-align: center;">
						<a-button type="primary" @click="onBack(false)" size="large">返回</a-button>
					</a-col>
				</a-row>
			</a-form>

			<!-- 弹窗 -->
			<a-modal style="width:850px" v-model:visible="showModal" :title="isEdit? '编辑票档': '新增票档'" @ok="onModifyModal"
				@cancel="onCancel">
				<a-spin :spinning="loading">
					<a-form ref="modalForm" scrollToFirstError :model="modelData" name="addForm" :labelCol="{ span: 6 }"
						:wrapperCol="{span: 16}">
						<a-form-item label="票档标题" name="title" :rules="[{ required: true, message: '必填项不允许为空' }]">
							<a-input v-model:value="modelData.title" placeholder="请输入票档标题" />
						</a-form-item>
						<!-- <a-form-item label="票档编号" name="ticketCode" :rules="[{ required: true, message: '必填项不允许为空' }]">
							<a-input v-model:value="modelData.ticketCode" placeholder="请输入票档编号" />
						</a-form-item> -->
						<a-form-item label="原价" name="facePrice" :rules="[{ required: true, message: '必填项不允许为空' }]">
							<a-input-number v-model:value="modelData.facePrice" :min="0" :precision="2" placeholder="请输入" @change="onPriceChange('facePrice')" /> 元
						</a-form-item>
						<a-form-item label="售价" name="salePrice" :rules="[{ required: true, message: '必填项不允许为空' }]" extra="实际销售价格">
							<a-input-number v-model:value="modelData.salePrice" :min="0" :precision="2" placeholder="请输入" @change="onPriceChange('salePrice')" /> 元
						</a-form-item>
						<a-form-item label="折扣百分比" name="discount" :rules="[{ required: true, message: '必填项不允许为空' }]">
							{{ modelData.discount || 0 }}%
							<!-- <a-input-number v-model:value="" :min="0" :precision="2" placeholder="请输入" @change="onPriceChange('discount')" /> % -->
						</a-form-item>
						<a-form-item label="票数" name="totalNum" :rules="[{ required: true, message: '必填项不允许为空' }]">
							<a-input-number v-model:value="modelData.totalNum" :min="0" :precision="0" placeholder="请输入" /> 张
						</a-form-item>
						<a-form-item label="座位数" name="seatNum" :rules="[{ required: true, message: '必填项不允许为空' }]" extra="单张票包含的座位数">
							<a-input-number v-model:value="modelData.seatNum" :min="0" :precision="0" placeholder="请输入" /> 张
						</a-form-item>
						<a-form-item label="一卡通价格" name="memberPrice" :rules="[{ required: true, message: '必填项不允许为空' }]">
							<a-input-number v-model:value="modelData.memberPrice" :min="0" :precision="0" placeholder="请输入" /> 元
						</a-form-item>
						<!-- <a-form-item label="一卡通限购票数" name="memberFirstBuyLimit">
							<a-input-number v-model:value="modelData.memberFirstBuyLimit" :min="0" :precision="0" placeholder="请输入" /> 张
						</a-form-item>
						
						<a-form-item label="是否允许一卡通优先购票" name="memberFirstBuy" :rules="[{ required: true, message: '必填项不允许为空' }]">
							<a-radio-group v-model:value="modelData.memberFirstBuy">
								<a-radio :value="1">是</a-radio>
								<a-radio :value="0">否</a-radio>
							</a-radio-group>
						</a-form-item>
						
						<a-form-item v-if="modelData.memberFirstBuy" label="一卡通优先购票时间" name="memberLimitTime" :rules="[{ required: true, message: '必填项不允许为空' }]">
							<a-date-picker v-model:value="modelData.memberLimitTime" showTime></a-date-picker>
						</a-form-item> -->
					</a-form>
				</a-spin>
			</a-modal>
		</a-spin>
	</div>
</template>

<script>
	import { Icon } from '@/components/icon/icon.js';
	import Header from '@/components/header/header.vue';
	import addressSelect from '@/components/addressSelect/index.vue';
	import Editor from '@/components/editor/editor.vue';
	import cinema from '@/components/cinema/index.vue';
	import site from '@/views/performance/components/site/index.vue';
	import {getCinemaAllList} from "@/service/modules/cinema";
	import preformClassify from '@/views/performance/components/classify/index.vue';
	import {
		getPerformDetail,
		savePerform,
		updatePerform,
		getPerformTagList,
		getPerformTipsList,
		getPerformRefundRulesList
	} from '@/service/modules/perform.js';
	export default {
		components: {
			Icon,
			Header,
			addressSelect,
			Editor,
			cinema,
			preformClassify,
			site
		},
		props: {
			perState: {
				type: Number,
				default: 0
			},
			id: {
				type: Number,
				default: 0
			},
		},
		data() {
			return {
				loading: false,
				isEdit: false,
				showModal: false,
				cinemaList: [],
				tipsList:[],
				tagList: [],
				noticeList: [],
				rulesList: [],
				fileList: [],
				seatFileList: [],
				modelRef: {
					siteId: [],
					// hallId: [],
					serviceIds: [],
					noticeIds: [],
					orderLimit: 0,
					isSelfSupport: 0,
					isPeopleRecommend: 0,
					isDayRecommend: 0,
					isWeekRecommend: 0,
					tickets: [],
					isDisabled: 0,
					isRecommend: 0,
				},
				modelData: {
					// memberFirstBuy: 0
				},
				editIndex: -1,
				list: [],
				columns: [{
					title: '票档标题',
					dataIndex: 'title',
					width: 120,
				}, 
				// {
				// 	title: '票档编号',
				// 	dataIndex: 'ticketCode',
				// 	width: 140
				// }, 
				{
					title: '定价',
					dataIndex: 'facePrice',
					width: 120,
				}, {
					title: '售价',
					dataIndex: 'salePrice',
					width: 120,
				}, {
					title: '折扣百分比(%)',
					dataIndex: 'discount',
					width: 120,
				}, {
					title: '票数',
					dataIndex: 'totalNum',
					width: 120,
				}, {
					title: '座位数',
					dataIndex: 'seatNum',
					width: 120
				}, {
					title: '一卡通价格',
					width: 120,
					dataIndex: 'memberPrice',
				}, {
					title: '操作',
					key: 'action',
					width: 100,
					fixed:'right'
				}],
			}
		},
		computed: {
			serviceDisabled(){
				return (id) => {
					if (id === 1) {
						return this.modelRef.serviceIds.includes(2)
					} else if (id === 2) {
						return this.modelRef.serviceIds.includes(1)
					} else if (id === 3) {
						return this.modelRef.serviceIds.includes(4)
					} else if (id === 4) {
						return this.modelRef.serviceIds.includes(3)
					} else {
						return false;
					}
				}
			}
		},
		created() {
			this.getCinemaAllList();
			this.getPerformTagList();
			this.getPerformRefundRulesList();
			this.getPerformTipsList(1);
			this.getPerformTipsList(2);
			if (this.perState === 2 && this.id) {
				this.getDetail()
			}
		},
		methods: {
			async onSubmit() {
				let postData = JSON.parse(JSON.stringify(this.modelRef));
				if ((!postData.serviceIds.includes(1) && !postData.serviceIds.includes(2)) 
					|| (!postData.serviceIds.includes(3) && !postData.serviceIds.includes(4))) {
					this.$message.warn('演出服务项未按要求勾选，无法提交！')
					return;
				}
				// postData.provinceId = postData.cityIds[0];
				// postData.cityId = postData.cityIds[1];
				// if (postData.cityIds.length === 3) {
				// 	postData.areaId = postData.cityIds[2];
				// }
				// delete postData.cityIds;
				// if (postData.hallId.length) {
				// 	postData.hallId = postData.hallId[0];
				// }
				// if (postData.hallId) {
				// 	postData.hallId = postData.hallId.join('');
				// }
				if (postData.siteId.length) {
					postData.siteId = postData.siteId[0];
				}
				// postData.longitude = postData.coordinate.split(',')[1];
				// postData.latitude = postData.coordinate.split(',')[0];
				// delete postData.coordinate;
				postData.tickets.forEach(item => {
					delete item.rowIndex;
				})
				this.loading = true;
				let ret;
				if (this.id) {
					ret = await updatePerform(postData);
				} else {
					ret = await savePerform(postData);
				}
				this.loading = false;
				if (ret.code === 200) {
					this.$message.success('操作成功！');
					this.onBack(true)
				}
			},
			async getDetail() {
				this.loading = true;
				let ret = await getPerformDetail({id: this.id});
				this.loading = false;
				if (ret.code === 200) {
					ret.data.tickets.forEach((item, index) => {
						item.rowIndex = index;
					})
					if (ret.data.siteId) {
						ret.data.siteId = [ret.data.siteId];
					} else {
						ret.data.siteId = [];
					}
					if (ret.data.posterUrl) {
						this.fileList = [{
							uid: 1,
							name: ret.data.posterUrl,
							url: ret.data.posterUrl
						}];
					}
					if (ret.data.seatImg) {
						this.seatFileList = [{
							uid: 0,
							name: ret.data.seatImg,
							url: ret.data.seatImg
						}];
					}
					delete ret.data.notice;
					delete ret.data.service;
					this.modelRef = ret.data;
				}
			},
			async getCinemaAllList() {
				let ret = await getCinemaAllList({});
				if (ret.code === 200) {
					this.cinemaList = ret.data;
				}
			},
			async getPerformTagList() {
				let ret = await getPerformTagList({
					page: 1,
					pageSize: 99999,
					isDisabled: 0
				})
				if (ret.code === 200) {
					this.tagList = ret.data.list;
				}
			},
			async getPerformTipsList(type){
				let ret = await getPerformTipsList({
					page: 1,
					pageSize: 99999,
					type: type,
					isDisabled: 0
				})
				if (ret.code === 200) {
					if (type === 1) {
						this.noticeList = ret.data.list;
					} else {
						this.tipsList = ret.data.list;
					}
				}
			},
			async getPerformRefundRulesList() {
				let ret = await getPerformRefundRulesList({
					page: 1,
					pageSize: 99999,
					isDisabled: 0
				})
				if (ret.code === 200) {
					this.rulesList = ret.data.list;
				}
			},
			onPriceChange(key){
				if (key !== 'discount' && this.modelData.facePrice && this.modelData.salePrice) {
					this.modelData.discount = ((1 - (this.modelData.salePrice/this.modelData.facePrice)) * 100).toFixed(2);
				} else if (key !== 'salePrice' && this.modelData.facePrice && this.modelData.discount) {
					this.modelData.salePrice = (this.modelData.facePrice * (1 - (this.modelData.discount / 100))).toFixed(2);
				} else if (key !== 'facePrice' && this.modelData.salePrice && this.modelData.discount) {
					this.modelData.facePrice = (this.modelData.salePrice / this.modelData.discount).toFixed(2);
				}
			},
			onChange() {
				this.modelRef.recommendTitle = '';
				this.modelRef.overview = '';
				this.modelRef.reason = '';
			},
			clearHall() {
				this.modelRef.hallId = [];
			},
			onBack(isRef) {
				this.$emit('back', isRef);
			},
			onOpenWin() {
				window.open('https://lbs.qq.com/getPoint/');
			},
			onNoticeChange(value) {
				if (!value.includes(2)) {
					this.modelRef.ruleId = undefined;
				}
			},
			onAddTicketLine() {
				this.modelData = {
					// memberFirstBuy: 0
				};
				this.editIndex = -1;
				this.showModal = true
			},
			onEditOne(record, index) {
				this.editIndex = index;
				let editData = this.$deepClone(record);
				if (editData.memberFirstBuy) {
					editData.memberLimitTime = this.moment(editData.memberLimitTime * 1000);
				}
				this.modelData = editData;
				this.showModal = true;
			},
			onDeleteOne(item, index) {
				this.$confirm({
					title: '提示',
					content: '确定删除吗？',
					onOk: async () => {
						this.modelRef.tickets.splice(index, 1);
					}
				})
			},
			onModifyModal() {
				this.$refs.modalForm.validate().then(()=> {
					let postData = this.$deepClone(this.modelData);
					if (postData.memberFirstBuy) {
						postData.memberLimitTime = postData.memberLimitTime.unix();
					} else {
						delete postData.memberLimitTime;
					}
					if (this.editIndex >= 0) {
						this.modelRef.tickets[this.editIndex] = postData;
					} else {
						postData.rowIndex = this.modelRef.tickets.length;
						this.modelRef.tickets.push(postData);
					}
					this.$refs.modalForm.resetFields();
					this.showModal = false;
				})
			},
			onCancel() {
				this.$refs.modalForm.resetFields();
				this.showModal = false;
				this.modelData = {
					// memberFirstBuy: 0
				};
			},
			onUploadChange(info) {
				if (info.file.status === 'done') {
					//console.log(info.file);
					this.fileList = info.fileList;
					const $fileList = [...this.fileList];
					this.fileList = $fileList.map(file => {
						if (file.response) {
							this.modelRef.posterUrl = file.response.data.imgUrl;
							file.url = file.response.data.imgUrl;
						}
						return file;
					});
				}
			},
			handleChange(info) {
				if (info.file.status === 'done') {
					//console.log(info.file);
					this.seatFileList = info.fileList;
					const $fileList = [...this.seatFileList];
					this.seatFileList = $fileList.map(file => {
						if (file.response) {
							this.modelRef.seatImg = file.response.data.imgUrl;
							file.url = file.response.data.imgUrl;
						}
						return file;
					});
				}
			}
		}
	}
</script>

<style scoped>
	.ui-header {
		padding: 20px 0 10px;
		margin-bottom: 20px;
		font-size: 16px;
		font-weight: 700;
		border-bottom: solid 1px #eee;
	}

	.ui-action {
		display: flex;
		justify-content: space-evenly;
	}
</style>