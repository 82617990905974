<template>
	<div>
		<a-spin :spinning="loading" v-show="!showDetail && !showschedule">
			<Header :title="'演出场次 当前演出：' + name" @back="onBackSession(false)"></Header>
			<a-form class="ui-form" ref="form" :model="formState" name="form" @finish="onSearch">
				<a-row>
					<a-form-item label="抢票状态" name="sellStatus" class="ui-form__item">
						<a-select v-model:value="formState.sellStatus" placeholder="请选择抢票状态" allow-clear
							style="width: 200px;">
							<a-select-option :value="0">未开始</a-select-option>
							<a-select-option :value="1">抢票中</a-select-option>
							<a-select-option :value="2">已结束</a-select-option>
						</a-select>
					</a-form-item>
					<a-form-item label="票状态" name="hasTicket" class="ui-form__item">
						<a-select v-model:value="formState.hasTicket" placeholder="请选择状态" allow-clear
							style="width: 200px;">
							<a-select-option :value="0">无票</a-select-option>
							<a-select-option :value="1">有票</a-select-option>
						</a-select>
					</a-form-item>
					<a-form-item label="状态" name="isDisabled" class="ui-form__item">
						<a-select v-model:value="formState.isDisabled" placeholder="请选择状态" allow-clear
							style="width: 200px;">
							<a-select-option :value="0">启用</a-select-option>
							<a-select-option :value="1">禁用</a-select-option>
						</a-select>
					</a-form-item>
				</a-row>
				<a-row>
					<a-col :span="18">
						<a-button v-permission="['performance_manage_list_addSession']" type="primary" @click="onAddSession">新增场次</a-button>
					</a-col>
					<a-col :span="6" style="text-align: right;">
						<a-button style="margin-right: 10px" type="primary" html-type="submit">搜索</a-button>
						<a-button @click="reset">重置</a-button>
					</a-col>
				</a-row>
			</a-form>

			<div style="margin-top: 20px;">
				<a-table rowKey="id" :pagination="pagination" :columns="columns" :dataSource="list"
					:scroll="{ x: 1200 }">
					<template #bodyCell="{ column, record }">
						<template v-if="column.key === 'showDate'">
							<div>{{ transDateTime(record.showStartTime) }}</div>
							至
							<div>{{ transDateTime(record.showEndTime) }}</div>
						</template>
						<template v-if="column.key === 'payDate'">
							<div v-if="record.sellStartTime">
								<div>{{ transDateTime(record.sellStartTime) }}</div>
								至
								<div>{{ transDateTime(record.sellEndTime) }}</div>
							</div>
							<div v-else>--</div>
						</template>
						<template v-if="column.key === 'sellStatus'">
							<div>{{ ['未开始', '抢票中', '已结束'][record.sellStatus] }}</div>
						</template>
						<template v-if="column.key === 'isDisabled'">
							<div>{{ record.isDisabled ? '已禁用' : '已启用' }}</div>
						</template>
						<template v-if="column.key === 'action'">
							<a-dropdown :trigger="['click', 'hover']">
								<a-button @click.prevent>操作 <Icon icon="DownOutlined"></Icon>
								</a-button>
								<template #overlay>
									<a-menu>
										<div @click="onSee(record)">
											<a-menu-item>查看</a-menu-item>
										</div>
										<div v-permission="['performance_manage_list_editSession']" @click="onEdit(record)">
											<a-menu-item>
												编辑
											</a-menu-item>
										</div>
										<div v-if="record.isElectronicTicket" @click="onSeat(record)">
											<a-menu-item>场次座位</a-menu-item>
										</div>
										<div v-permission="['performance_manage_list_disabledSession']" v-if="record.isDisabled" @click="onChangeState(record)">
											<a-menu-item>
												启用
											</a-menu-item>
										</div>
										<div v-permission="['performance_manage_list_disabledSession']" v-else @click="onChangeState(record)">
											<a-menu-item>
												禁用
											</a-menu-item>
										</div>
										<div v-permission="['performance_manage_list_deleteSession']" @click="onDelete(record)">
											<a-menu-item>
												删除
											</a-menu-item>
										</div>
									</a-menu>
								</template>
							</a-dropdown>
						</template>
					</template>
				</a-table>
			</div>
		</a-spin>
		<div v-if="showDetail">
			<detail :isEdit="isEdit" :isSee="isSee" :id="id" :scheduleId="scheduleId" @back="onBack"></detail>
		</div>
		<div v-if="showschedule">
			<seatList :id="id" :scheduleId="scheduleId" :name="name" @back="onBack"></seatList>
		</div>
	</div>
</template>

<script>
	import {
		Icon
	} from '@/components/icon/icon.js';
	import Header from '@/components/header/header.vue';
	import detail from './detail.vue';
	import seatList from './seatList.vue';
	import {
		getPerformSchedulesList,
		deletePerformSchedules,
		disabledPerformSchedules
	} from '@/service/modules/perform.js';
	export default {
		components: {
			Icon,
			Header,
			detail,
			seatList
		},
		props: {
			id: {
				type: Number,
				default: 0
			},
			name: {
				type: String,
				default: ''
			}
		},
		data() {
			return {
				scheduleId: 0,
				loading: false,
				showAll: false,
				isEdit: false,
				isSee: false,
				searchData: {},
				showModal: false,
				showDetail: false,
				showschedule: false,
				formState: {},
				list: [],
				columns: [{
					title: '演出时间',
					key: 'showDate',
				}, {
					title: '开抢时间',
					key: 'payDate',
				}, {
					title: '抢票状态',
					key: 'sellStatus',
					width: 120
				}, {
					title: '总票数',
					dataIndex: 'totalTicket',
					width: 120
				}, {
					title: '已抢票数',
					dataIndex: 'sellNum',
					width: 120
				}, {
					title: '剩余票数',
					dataIndex: 'totalStock',
					width: 120
				}, {
					title: '状态',
					key: 'isDisabled',
					width: 120
				}, {
					title: '操作',
					key: 'action',
					fixed: 'right',
					width: 120,
				}],
				pagination: {
					showSizeChanger: true,
					showQuickJumper: true,
					size: "middle",
					pageSizeOptions: ['10', '20', '50', '100', '500'],
					showTotal: (total) => {
						return "共 " + total + " 条";
					},
					total: 0,
					current: 1,
					pageSize: 10,
					onChange: (page) => {
						this.pagination.current = page;
						this.getData();
					},
					onShowSizeChange: (page, pageSize) => {
						this.pagination.current = 1;
						this.pagination.pageSize = pageSize;
						this.getData();
					}
				},
			}
		},
		created() {
			if (this.id) {
				this.getData();
			}
		},
		methods: {
			onBackSession(isRef) {
				this.$emit('back', isRef);
			},
			onBack(isRef) {
				this.showschedule = false;
				this.showDetail = false;
				if (isRef) {
					this.getData();
				}
			},
			reset() {
				this.$refs.form.resetFields();
				this.onSearch();
			},
			onSearch() {
				this.pagination.current = 1;
				this.searchData = JSON.parse(JSON.stringify(this.formState));
				this.getData();
			},
			async getData() {
				this.loading = true;
				let ret = await getPerformSchedulesList({
					page: this.pagination.current,
					pageSize: this.pagination.pageSize,
					id: this.id,
					...this.searchData
				})
				this.loading = false;
				if (ret.code === 200) {
					this.list = ret.data.list;
					this.pagination.total = ret.data.totalCount
				}
			},
			onAddSession() {
				this.isSee = false;
				this.isEdit = false;
				this.scheduleId = 0;
				this.showDetail = true
			},
			onEdit(item) {
				this.isSee = false;
				this.isEdit = true;
				this.scheduleId = item.id;
				this.showDetail = true;
			},
			onSee(item) {
				this.isSee = true;
				this.isEdit = false;
				this.scheduleId = item.id;
				this.showDetail = true;
			},
			onSeat(item) {
				this.scheduleId = item.id;
				this.showschedule = true;
			},
			onChangeState(item) {
				this.$confirm({
					title: '提示',
					content: `确定${ item.isDisabled ? '禁用' : '启用' }该选项吗？`,
					onOk: async () => {
						this.loading = true;
						let ret = await disabledPerformSchedules({
							id: item.id,
							isDisable: item.isDisabled ? 0 : 1
						})
						this.loading = false;
						if (ret.code === 200) {
							this.$message.success(`${ item.isDisabled ? '禁用' : '启用' }成功！`);
							this.getData();
						}
					}
				})
			},
			onDelete(item) {
				this.$confirm({
					title: '提示',
					content: '确定删除吗？',
					onOk: async () => {
						this.loading = true;
						try {
							let ret = await deletePerformSchedules({
								id: item.id
							});
							this.loading = false;
							if (ret.code === 200) {
								this.$message.success('删除成功！');
								this.getData();
							}
						} catch (e) {
							this.loading = false;
						}
					}
				})
			}
		}
	}
</script>

<style scoped>
	.ui-form {
		margin-top: 20px;
	}

	.ui-form__item {
		margin-right: 30px;
	}
</style>