<template>
	<div>
        <Header title="演出展示评价" @back="onBack(false)"></Header>
		<a-spin :spinning="loading">
			<a-form class="ui-form" ref="form" :model="formState" scrollToFirstError name="form" @finish="onSearch">
				<a-row>
                        <a-form-item class="ui-form__item" label="用户名称" name="name">
						    <a-input v-model:value="formState.name" style="width:200px" placeholder="请输入用户名称"></a-input>
					    </a-form-item>

                        <a-form-item class="ui-form__item" label="用户手机" name="phone">
						    <a-input v-model:value="formState.phone" style="width:200px" placeholder="请输入手机号码"></a-input>
					    </a-form-item>
                        <a-form-item label="评价类型" name="type" class="ui-form__item">
                            <a-select v-model:value="formState.type" allow-clear style="width:200px">
                                <a-select-option :value="0">全部</a-select-option>
                                <a-select-option :value="1">有图</a-select-option>
                                <a-select-option :value="2">精选</a-select-option>
                                <a-select-option :value="3">有图+精选</a-select-option>
                            </a-select>
                        </a-form-item>
                        <a-form-item label="状态" name="status" class="ui-form__item">
                            <a-select v-model:value="formState.status" allow-clear style="width:200px">
                                <a-select-option :value="0">全部</a-select-option>
                                <a-select-option :value="1">启用</a-select-option>
                                <a-select-option :value="2">禁用</a-select-option>
                            </a-select>
                        </a-form-item>

          <a-col>
            <a-form-item label="展示位置" name="location" class="ui-form__item">
              <a-select v-model:value="formState.location" allow-clear style="width:200px">
                <a-select-option :value="0">全部</a-select-option>
                <a-select-option :value="1">轮播</a-select-option>
                <a-select-option :value="2">底部</a-select-option>
              </a-select>
            </a-form-item>
          </a-col>
				</a-row>
				<a-row>
					<a-col :span="24" style="text-align: right;">
						<a-button style="margin-right: 10px" type="primary" html-type="submit">搜索</a-button>
						<a-button @click="reset">重置</a-button>
					</a-col>
				</a-row>
			</a-form>			
			
			<div style="margin-top: 20px;">
				<a-table rowKey="id" :pagination="pagination" :columns="columns" :dataSource="list">
					<template #bodyCell="{ column, record }">
                        <template v-if="column.key === 'content'">
							<div v-if="record.content.length > 24">
								<div class="ui-from__content">{{record.content}}</div>
                                <a-button class="ui-form__text" type="link">查看全文</a-button>
							</div>
							<div v-else>{{record.content}}</div>
                        </template>
						<template v-if="column.key === 'action'">
							<a-dropdown :trigger="['click', 'hover']">
								<a-button @click.prevent>操作 <Icon icon="DownOutlined"></Icon>
								</a-button>
								<template #overlay>
									<a-menu>
										<div @click="onEditPriority(record)">
											<a-menu-item>
												编辑优先级
											</a-menu-item>
										</div>
										<div>
											<a-menu-item>
												启用/禁用
											</a-menu-item>
										</div>
                                        <div @click="onEvaluateDetail(record)">
											<a-menu-item>
												评价详情
											</a-menu-item>
										</div>
										<div @click="onEvaluateReview(record)">
											<a-menu-item>
												评价详情
											</a-menu-item>
										</div>
									</a-menu>
								</template>
							</a-dropdown>
						</template>
					</template>
				</a-table>
			</div>
			
			<a-modal v-model:visible="showModal" title="编辑优先级" @ok="onSubmit" @cancel="onCancel">
				<a-spin :spinning="loading">
					<a-form ref="editForm" scrollToFirstError :model="modelRef" name="editForm" :labelCol="{ span: 5 }" :wrapperCol="{span: 16}">
						<a-form-item label="优先级" name="" :rules="[{ required: true, message: '必填项不允许为空' }]">
							<a-input  placeholder="请输入优先级"/>
						</a-form-item>
					</a-form>
				</a-spin>
			</a-modal>
		</a-spin>
	</div>
</template>

<script>
	import {
		Icon
	} from '@/components/icon/icon.js';
    import Header from '@/components/header/header.vue';
	export default {
		components: {Icon,Header},
		data() {
			return {
				loading: false,
				searchData: {},
				showModal: false,
				modelRef: {},
        formState: {
          type: 0,
          status: 0,
          location: 0,
        },
				list: [{id:1,priority:1,content:'评价内容评价内容评价内容评价内容评价内容评价内容评价内容评评价内容评价内容评价内容评价内容评价内容评价内容评价内容评'},
				{id:1,priority:2,content:'评价内容评价内容评价内容评价内容评价内容评价内容'}],
				columns: [{
					title: '用户',
					dataIndex: '',
          align: 'center',
          width: 140
				}, {
					title: '手机号码',
					dataIndex: '',
          align: 'center',
          width: 200
				}, {
					title: '评价类型',
					dataIndex: '',
          key: '',
          align: 'center',
          width: 200
				}, {
					title: '评价日期',
					dataIndex: '',
          key: '',
          align: 'center',
          width: 200
				}, {
					title: '评价内容',
					dataIndex: 'content',
          key: 'content',
          align: 'center',
          width: 200
				}, {
					title: '优先级',
					dataIndex: 'priority',
          key: 'priority',
          align: 'center',
          width: 100
				}, {
					title: '展示位置',
					dataIndex: '',
          key: '',
          align: 'center',
          width: 120
				}, {
					title: '状态',
					dataIndex: '',
          key: '',
          align: 'center',
          width: 120
				}, {
					title: '操作',
					key: 'action',
          align: 'center',
          width: 120
				}],
        pagination: {
          showSizeChanger: true,
          showQuickJumper: true,
          size: "middle",
          pageSizeOptions: ['10','20', '50', '100', '500'],
          showTotal: (total) => {
            return "共 " + total + " 条";
          },
          total: 0,
          current: 1,
          pageSize: 10,
          onChange: (page) => {
            this.pagination.current = page;
            this.getData();
          },
          onShowSizeChange: (page, pageSize) => {
            this.pagination.current = 1;
            this.pagination.pageSize = pageSize;
            this.getData();
          }
        },
			}
		},
    created() {
      this.getData();
    },
    methods: {
      onBack(isRef) {
				this.$emit('back', isRef);
			},
      reset() {
        this.$refs.form.resetFields();
        this.onSearch();
      },
      onSearch() {
        this.pagination.current = 1;
        this.searchData = JSON.parse(JSON.stringify(this.formState));
        this.getData();
      },
      async getData() {

      },
      onLook(value){

      },
      onAddTag(){
        this.showModal = true
      },
			onEditPriority(item){
        this.showModal = true
			},
      onSubmit(){
          this.showModal = false
          this.modelRef = {}
      },
      onCancel(){
          this.modelRef = {}
      },
			onEvaluateDetail(item){
				this.$router.push({
					path:'/comment/film/evaluateDetail',
				})
			},
			onEvaluateReview(item){
				this.$router.push({
					path:'/comment/film/commentDetail',
				})
			}
		}
	}
</script>

<style scoped>
.ui-form{
    margin-top: 20px;
}

.ui-from__content {
	overflow: hidden;
	text-overflow: ellipsis;
	display: -webkit-box;
	-webkit-line-clamp: 2;
	-webkit-box-orient: vertical;
}

.ui-form__text{
	position: absolute;
	right: 0;
	bottom: -5px;
}

.ui-form__item {
   margin-right: 30px;
}
</style>
