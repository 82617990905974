<template>
	<a-spin :spinning="loading">
		<a-select v-model:value="classifyId" @change="onChange" :placeholder="placeholder" style="min-width: 200px;">
			<a-select-option v-for="item in list" :key="item.id" :value="item.id">{{ item.name }}</a-select-option>
		</a-select>
	</a-spin>
</template>

<script>
	import { getPerformClassifyList } from '@/service/modules/perform.js';
	export default {
		model: {
			prop: 'value'
		},
		props: {
			value: {
				type: [Number, undefined],
				default: undefined
			},
			placeholder: {
				type: String,
				default: '请选择'
			}
		},
		watch: {
			value(newVal) {
				this.classifyId = newVal;
			}
		},
		data() {
			return {
				loading: false,
				classifyId: undefined,
				list: [],
			}
		},
		created() {
			this.getData();
		},
		methods: {
			async getData() {
				this.loading = true;
				let ret = await getPerformClassifyList({
					page: 1,
					pageSize: 99999
				})
				this.loading = false;
				if (ret.code === 200) {
					this.list = ret.data.list;
				}
			},
			onChange(e) {
				this.$emit('update:value', e);
			}
		}
	}
</script>

<style>
</style>